import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { useSwipeable } from "react-swipeable"

import { useWindowWidth } from "../../services/hooks"

import "./news.scss"

import Arrow from "../../images/arrow.svg"
import variables from "../variables.scss"

const News = ({ section }) => {
  const [offset, setOffset] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [mobileWidth, setMobileWidth] = useState(0)
  const [offsetChange, setOffsetChange] = useState(0)
  const newsPreview = useRef(null)
  const width = useWindowWidth()
  const nrOfPosts = 3

  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        homepage {
          components {
            posts {
              id
              title
              content
              description
              published_at
              thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { posts } = pages.homepage.components

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (offset - offsetChange > nrOfPosts * -offsetChange) {
        setOffset(offset => offset - offsetChange)
      }
    },
    onSwipedRight: () => {
      if (offset + offsetChange <= 0) {
        setOffset(offset => offset + offsetChange)
      }
    },
  })

  const refPassthrough = el => {
    handlers.ref(el)
    newsPreview.current = el
  }

  useEffect(() => {
    if (newsPreview.current) {
      newsPreview.current.style.transform = `translateX(${offset}px)`
    }
  }, [offset])

  useEffect(() => {
    setIsMobile(width <= parseInt(variables.mobile))
  }, [width])

  useEffect(() => {
    if (isMobile && width) {
      setMobileWidth(width - 150)
    }
  }, [isMobile, width])

  useEffect(() => {
    if (mobileWidth) {
      setOffsetChange(mobileWidth + 20)
    }
  }, [mobileWidth])

  return (
    <>
      {posts.length >= 2 ? (
        <section id="news">
          <div>
            <div className="title-row">
              <h3>{section.title}</h3>
              {!isMobile && (
                <Link to="/news">
                  Összes hír
                  <Arrow />
                </Link>
              )}
            </div>
            <div className="preview-row" {...handlers} ref={refPassthrough}>
              {posts
                .sort(
                  (a, b) =>
                    +new Date(b.published_at) - +new Date(a.published_at)
                )
                .slice(0, nrOfPosts)
                .map((p, i) => (
                  <Link
                    key={i}
                    to={`/news/${p.id}`}
                    className="post-preview"
                    style={{
                      minWidth: `${isMobile ? mobileWidth : 420}px`,
                      maxWidth: `${isMobile ? mobileWidth : 420}px`,
                    }}
                  >
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(https://www.villagemedia.hu/api${p.thumbnail.url})`,
                      }}
                    ></div>
                    <h4 className="post-title">
                      <span>{p.title}</span>
                    </h4>
                    <div className="post-description">
                      <p>{p.description}</p>
                    </div>
                  </Link>
                ))}
            </div>
            {isMobile && (
              <div className="mobile-link">
                <Link to="/news">
                  Összes hír
                  <Arrow />
                </Link>
              </div>
            )}
          </div>
        </section>
      ) : null}
    </>
  )
}

export default News
