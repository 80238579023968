import React, { useRef, useState } from "react"
import { useSwipeable } from "react-swipeable"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm';

import "./feedback.scss"

import Arrow from "../../images/arrow.svg"

const Feedback = ({ section }) => {
  const feedbackContainer = useRef(null)
  const itemsRef = useRef([])
  const [marginLeft, setMarginLeft] = useState(0)
  const [clickable, setClickable] = useState(true)

  const calcMargin = (direction, style) => {
    const offset =
      itemsRef.current && itemsRef.current.length
        ? itemsRef.current[0].offsetWidth
        : 0
    const tmp = parseInt(style.marginLeft) + direction * offset
    if (-1 * offset * (section.blocks.length - 1) <= tmp && tmp <= 0) {
      return tmp
    }
  }
  const scrollToNext = direction => {
    if (feedbackContainer.current !== null && clickable) {
      const style = window.getComputedStyle(feedbackContainer.current)
      const margin = calcMargin(direction, style)
      setMarginLeft(Math.abs(margin))
      feedbackContainer.current.style.marginLeft = `${margin}px`
      setClickable(false)
      setTimeout(() => {
        setClickable(true)
      }, 300)
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => scrollToNext(-1),
    onSwipedRight: () => scrollToNext(1),
  })
  const refPassthrough = el => {
    handlers.ref(el)
    feedbackContainer.current = el
  }

  return (
    <section id="feedback">
      <div>
        <h3>{section.title}</h3>
        <div className="content">
          <div
            {...handlers}
            ref={refPassthrough}
            className="feedback-container"
          >
            {section.blocks.map((b, i) => (
              <div
                ref={el => (itemsRef.current[i] = el)}
                className="feedback"
                key={i}
              >
                <div className="feedback-text">
                  <ReactMarkdown children={b.content} remarkPlugins={[remarkGfm]} />
                </div>
                <div className="feedback-author">{b.title}</div>
              </div>
            ))}
          </div>
          <div className="arrow-container">
            {marginLeft !== 0 && (
              <Arrow
                role="button"
                className="arrow left"
                onClick={() => scrollToNext(1)}
              />
            )}
            {(marginLeft === 0 ||
              (feedbackContainer &&
                feedbackContainer.current &&
                parseInt(feedbackContainer.current.offsetWidth) - 650 >
                  marginLeft)) && (
              <Arrow
                role="button"
                className="arrow right"
                onClick={() => scrollToNext(-1)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Feedback
