import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Public from "../../images/public.svg"
import Business from "../../images/business.svg"
import Educational from "../../images/educational.svg"
import LifeStyle from "../../images/lifestyle.svg"
import Industry from "../../images/industry.svg"
import RollingAd from "../../images/rolling-ad.svg"

import "./sectorVisualisation.scss"

const Circles = ({
  cx,
  cy,
  lastStroke,
  children,
  className,
  iconAdjustment,
  visited,
  setHighlightedSector,
  sector,
}) => (
  <g
    className={className}
    onMouseEnter={() => {
      if (setHighlightedSector) {
        setHighlightedSector({ value: sector, shouldMove: false })
      }
    }}
  >
    <Link
      to={sector ? `/portfolio/${sector}` : "/"}
      state={{
        modal: true,
      }}
    >
      <circle cx={cx} cy={cy} r="70" fill="white" fillOpacity="0.25" />
      <circle cx={cx} cy={cy} r="69.5" stroke="white" strokeOpacity="0.25" />
      <circle cx={cx} cy={cy} r="55" fill="white" fillOpacity="0.5" />
      <circle cx={cx} cy={cy} r="54.5" stroke="white" strokeOpacity="0.5" />
      <circle
        cx={cx}
        cy={cy}
        r="39.5"
        fill="white"
        fillOpacity="0.75"
        stroke={lastStroke}
      />
      <svg
        className="icon"
        x={cx - 40.25 / 2}
        y={cy - 40.25 / 2}
        style={{ opacity: visited ? 1 : 0 }}
      >
        <g
          transform={`rotate(${iconAdjustment || 0} ${40.25 / 2} ${40.25 / 2})`}
        >
          {children}
        </g>
      </svg>
    </Link>
  </g>
)

const Center = ({ className, visited }) => (
  <g className={className}>
    <Circles cx={218} cy={240} lastStroke="none" />
    <circle
      cx="218"
      cy="240"
      r="19.5"
      fill="white"
      fillOpacity="0.75"
      stroke="#93A7A2"
    />
    <path
      d="M104.767 305.125L201.329 249.375"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
    <path
      d="M235.104 229.875L331.233 174.375"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
    <path
      d="M104.767 174.375L201.329 230.125"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
    <path
      d="M235.104 249.625L331.233 305.125"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
    <path
      d="M218 109V220.5"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
    <path
      d="M218 259.5V370.5"
      stroke="#93A7A2"
      style={{ opacity: visited ? 1 : 0 }}
    />
  </g>
)

const SectorVisualisation = ({
  sectors,
  highlightedSector,
  setHighlightedSector,
  visited,
}) => {
  const [currentRotation, setCurrentRotation] = useState(0)
  const visualizations = [
    { key: "public", cx: 70, cy: 155, icon: <Public />, rotate: 0 },
    { key: "business", cx: 218, cy: 70, icon: <Business />, rotate: -60 },
    {
      key: "educational",
      cx: 366,
      cy: 155,
      icon: <Educational />,
      rotate: -120,
    },
    { key: "lifestyle", cx: 366, cy: 325, icon: <LifeStyle />, rotate: -180 },
    { key: "industry", cx: 218, cy: 410, icon: <Industry />, rotate: -240 },
    { key: "rolling-ad", cx: 70, cy: 325, icon: <RollingAd />, rotate: -300 },
  ]
  useEffect(() => {
    if (highlightedSector.value && highlightedSector.shouldMove) {
      setCurrentRotation(
        visualizations.find(v => v.key === highlightedSector.value).rotate
      )
    }
  }, [highlightedSector, visualizations])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="436"
      height="480"
      viewBox="0 0 436 480"
      fill="none"
      className="sector-visualisation"
      style={{
        transform: `rotate(
          ${
            highlightedSector.value && highlightedSector.shouldMove
              ? visualizations.find(v => v.key === highlightedSector.value)
                  .rotate
              : visited
              ? currentRotation
              : 360
          }deg)`,
      }}
    >
      {sectors.map(s => {
        const visualization = visualizations.find(v => v.key === s.key)
        return (
          <Circles
            key={s.key}
            cx={visited ? visualization.cx : 218}
            cy={visited ? visualization.cy : 240}
            lastStroke="#93A7A2"
            className={`pointer ${
              highlightedSector.value && s.key !== highlightedSector.value
                ? "fade"
                : ""
            }`}
            iconAdjustment={
              highlightedSector.value && highlightedSector.shouldMove
                ? visualizations.find(v => v.key === highlightedSector.value)
                    .rotate * -1
                : currentRotation * -1
            }
            visited={visited}
            setHighlightedSector={setHighlightedSector}
            sector={s.key}
          >
            {visualization.icon}
          </Circles>
        )
      })}
      <Center
        className={`${highlightedSector.value ? "fade" : ""}`}
        visited={visited}
      />
    </svg>
  )
}

export default SectorVisualisation
