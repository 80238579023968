import React, { useEffect, useState } from "react"

import { useWindowWidth } from "../../services/hooks"

import "./methodology.scss"
import variables from "../variables.scss"

const Circles = ({ c, isCenter = false, setActiveItem, isActive }) => {
  const { cx, cy, order } = c
  return (
    <g onMouseEnter={setActiveItem} onClick={setActiveItem}>
      {!isCenter && (
        <circle cx={cx} cy={cy} r="54.5593" fill="white" fillOpacity="0.25" />
      )}
      <circle cx={cx} cy={cy} r="41.3328" fill="white" fillOpacity="0.25" />
      <circle
        cx={cx}
        cy={cy}
        r="40.9071"
        stroke="white"
        strokeOpacity="0.25"
        strokeWidth="0.851456"
      />
      {!isCenter ? (
        <>
          <circle
            cx={cx}
            cy={cy}
            r="28.1063"
            fill="white"
            fillOpacity="0.5"
            stroke="#B8CEC9"
            strokeWidth="0.851456"
            strokeOpacity="0.75"
          />
          <svg>
            <g className="serial-number-container">
              <text
                className={`serial-number ${isActive ? "visible" : ""}`}
                textAnchor="middle"
                x={cx}
                y={cy + 6}
                stroke="black"
                fill="black"
                fontSize="18"
                fontFamily={`"Open Sans", sans-serif"`}
                fontWeight="400"
              >
                {order}
              </text>
            </g>
          </svg>
        </>
      ) : (
        <circle cx={cx} cy={cy} r="28.1063" fill="white" fillOpacity="0.5" />
      )}
      <circle
        cx={cx}
        cy={cy}
        r="27.6806"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="0.851456"
      />
    </g>
  )
}
const Center = () => (
  <Circles c={{ cx: "175.731", cy: "171.308" }} isCenter={true} />
)

const Methodology = ({ section }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [visibleIllustration, setVisibleIllustration] = useState(false)
  const width = useWindowWidth()

  const outerCircles = [
    { cx: 175.423, cy: 54.8927, order: 1 },
    { cx: 175.423, cy: 287.108, order: 4 },
    { cx: 275.47, cy: 113.134, order: 2 },
    { cx: 75.0172, cy: 113.134, order: 6 },
    { cx: 75.0172, cy: 228.866, order: 5 },
    { cx: 275.47, cy: 228.866, order: 3 },
  ]
  useEffect(() => {
    setVisibleIllustration(width >= parseInt(variables.tablet))
  }, [width])
  return (
    <section id="methodology">
      <div>
        <h3>{section.title}</h3>
        {visibleIllustration ? (
          <div
            className="methodology-illustration"
            onMouseLeave={() => setActiveItem(0)}
          >
            <div className="label-container">
              {[...section.blocks]
                .sort((a, b) => a.title - b.title)
                .map((b, i) => (
                  <div
                    className={`list-item item-${b.title} ${
                      !activeItem ||
                      (activeItem && activeItem.order === Number(b.title))
                        ? "active"
                        : ""
                    }`}
                    key={i}
                  >
                    <div className={`list-item-text `}>{b.content}</div>
                  </div>
                ))}
            </div>
            <svg
              width="350"
              height="342"
              viewBox="0 0 350 342"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Center />
              <path
                d="M186.218 171.308C186.218 177.1 181.523 181.795 175.732 181.795C169.94 181.795 165.246 177.1 165.246 171.308C165.246 165.517 169.94 160.822 175.732 160.822C181.523 160.822 186.218 165.517 186.218 171.308Z"
                fill="white"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              {outerCircles.map(c => (
                <Circles
                  key={c.order}
                  c={c}
                  setActiveItem={() => setActiveItem(c)}
                  isActive={!activeItem || activeItem.order === c.order}
                />
              ))}
              <path
                d="M175.574 83.0441V160.419"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              <path
                d="M99.0249 127.022L166.034 165.71"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              <path
                d="M251.89 127.105L184.881 165.792"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              <path
                d="M175.574 181.912V259.287"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              <path
                d="M184.881 176.539L251.89 215.226"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
              <path
                d="M166.268 176.539L99.2593 215.226"
                stroke="#B8CEC9"
                strokeWidth="0.851456"
              />
            </svg>
          </div>
        ) : (
          <div className="methodology-list">
            {[...section.blocks]
              .sort((a, b) => a.title - b.title)
              .map((b, i) => (
                <div className="list-item" key={i}>
                  <div className="sort-number">{b.title}</div>
                  <div className="list-item-text">{b.content}</div>
                </div>
              ))}
            <div className="line"></div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Methodology
