import React, { useState, cloneElement } from "react"
import { graphql, useStaticQuery } from "gatsby"
import VizSensor from "react-visibility-sensor"
import { Link } from "gatsby"

import Counters from "../common/counters"
import Sectors from "./sectors"
import SectorVisualisation from "./sectorVisualisation"
import CountryMap from "../common/countryMap"

import "./portfolio.scss"

import Arrow from "../../images/arrow.svg"

const Portfolio = ({ section }) => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        homepage {
          components {
            counters {
              title
              value
              unit
            }
            sectors {
              title
              key
            }
            partners {
              name
              regions {
                key
              }
              lat
              long
            }
          }
        }
      }
    }
  `)
  const { counters, sectors, partners } = pages.homepage.components
  const [sections, setSections] = useState(
    section.blocks.map(s => ({ ...s, visited: false }))
  )
  const [highlightedSector, setHighlightedSector] = useState({
    value: null,
    shouldMove: false,
  })
  const highlights = [
    <Counters counters={counters} />,
    <Sectors
      sectors={sectors}
      highlightedSector={highlightedSector}
      setHighlightedSector={setHighlightedSector}
    />,
  ]
  const visualization = [
    <CountryMap partners={partners} />,
    <SectorVisualisation
      sectors={sectors}
      highlightedSector={highlightedSector}
      setHighlightedSector={setHighlightedSector}
    />,
  ]
  const onChange = (isVisible, index) => {
    if (isVisible) {
      setSections(sections =>
        sections.map((c, i) => ({
          ...c,
          visited: i === index ? true : c.visited,
        }))
      )
    }
  }
  return (
    <>
      {sections.map((b, i) => (
        <section id={`portfolio-${i + 1}`} key={i}>
          <div>
            <VizSensor
              onChange={isVisible => onChange(isVisible, i)}
              partialVisibility
            >
              <div className={`breakdown-option ${b.visited ? "visited" : ""}`}>
                <div className="left-container">
                  <div className="col">
                    <h3>{section.title}</h3>
                    <Link
                      to={`/portfolio#portfolio-block-${i + 1}`}
                      className="arrow"
                    >
                      <Arrow />
                    </Link>
                  </div>
                  <div className="col">
                    <div className="label">
                      <h4>{b.title}</h4>
                      <p>{b.content}</p>
                    </div>
                    <div className="highlights">
                      {cloneElement(highlights[i], {
                        visited: b.visited,
                      })}
                    </div>
                  </div>
                </div>
                <div className="right-container">
                  {cloneElement(visualization[i], {
                    visited: b.visited,
                  })}
                </div>
              </div>
            </VizSensor>
          </div>
        </section>
      ))}
    </>
  )
}

export default Portfolio
