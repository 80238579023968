import React from "react"
import { Link } from "gatsby"

import "./sectors.scss"

const Sectors = ({ sectors, highlightedSector, setHighlightedSector }) => (
  <div id="sectors">
    {sectors.map((s, i) => (
      <Link
        to={`/portfolio/${s.key}`}
        state={{
          modal: true,
        }}
        onMouseEnter={() =>
          setHighlightedSector({ value: s.key, shouldMove: true })
        }
        className={`sector-title${
          highlightedSector.value && s.key !== highlightedSector.value
            ? " fade"
            : ""
        }`}
        key={i}
      >
        {s.title}
      </Link>
    ))}
  </div>
)

export default Sectors
