import React, { useState } from "react"

import "./questions.scss"

import Arrow from "../../images/flat_arrow.svg"

const Questions = ({ section }) => {
  const [questions, setQuestions] = useState(
    section.blocks.map(b => ({ ...b, isOpen: false }))
  )
  return (
    <section id="questions">
      <div>
        <h3>{section.title}</h3>
        <div className="questions-list">
          {questions.map((d, i) => (
            <button
              className="question-item"
              key={i}
              onClick={() =>
                setQuestions(prev =>
                  prev.map((q, index) => ({
                    ...q,
                    isOpen: i === index ? !q.isOpen : q.isOpen,
                  }))
                )
              }
            >
              <div className="question-title">
                <h4>{d.title}</h4>
                <Arrow className={`${d.isOpen ? "open" : ""}`} />
              </div>
              <div className={`question-content ${d.isOpen ? "open" : ""}`}>
                <p>{d.content}</p>
              </div>
            </button>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Questions
