import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Quote from "../components/home/quote"
import WhyUs from "../components/home/whyus"
import Portfolio from "../components/home/portfolio"
import Methodology from "../components/home/methodology"
import Feedback from "../components/home/feedback"
import Questions from "../components/home/questions"
import News from "../components/home/news"
import Contact from "../components/common/contact"
import Relations from "../components/common/relations"

const IndexPage = () => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        homepage {
          title
          sections {
            title
            key
            blocks {
              title
              content
              order
            }
          }
        }
      }
    }
  `)
  const { title, sections } = pages.homepage
  return (
    <Layout>
      <SEO title={title} />
      <Quote section={sections.find(s => s.key === "quote")} />
      <Portfolio section={sections.find(s => s.key === "portfolio")} />
      <WhyUs section={sections.find(s => s.key === "whyus")} />
      <Methodology section={sections.find(s => s.key === "methodology")} />
      <News section={sections.find(s => s.key === "news")} />
      <Feedback section={sections.find(s => s.key === "feedback")} />
      <Questions section={sections.find(s => s.key === "questions")} />
      <Contact section={sections.find(s => s.key === "contact")} />
      <Relations section={sections.find(s => s.key === "relations")} />
    </Layout>
  )
}

export default IndexPage
