import React, { useContext, useEffect, useCallback, useState } from "react"
import VizSensor from "react-visibility-sensor"
import anime from "animejs"

import { ScrollContext } from "../layout"
import { useWindowWidth } from "../../services/hooks"

import "./quote.scss"
import variables from "../variables.scss"

const Quote = ({ section }) => {
  const [, setFirstBlockVisibility] = useContext(ScrollContext)
  const width = useWindowWidth()
  const isMobile = width <= parseInt(variables.mobile)
  const [fontSize, setFontSize] = useState(40)
  useEffect(() => {
    anime({
      targets: ".welcome-quote .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      delay: (_, i) => 10 * (i + 1),
    })
  }, [])
  const quoteRef = useCallback(node => {
    if (node) {
      const height = node.offsetHeight
      if (!isMobile && height <= 400) {
        setFontSize(Math.floor(height / 12))
      }
      if (isMobile) {
        setFontSize(Math.floor(width / 18))
      }
    }
  })
  return (
    <VizSensor
      onChange={isVisible => setFirstBlockVisibility(isVisible)}
      partialVisibility
    >
      <section id="quote" ref={quoteRef} style={{ fontSize }}>
        <div>
          {section.blocks.map((b, i) => (
            <div className="welcome-quote" key={i}>
              {b.content.split("").map((l, i) => (
                <span key={i} className="letter">
                  {l}
                </span>
              ))}
            </div>
          ))}
        </div>
      </section>
    </VizSensor>
  )
}

export default Quote
