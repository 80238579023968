import React, { useState, useRef, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm';

import { useSwipeable } from "react-swipeable"

import { useWindowWidth } from "../../services/hooks"

import "./whyus.scss"
import variables from "../variables.scss"

import Arrow from "../../images/arrow.svg"

const WhyUs = ({ section }) => {
  const [open, setOpen] = useState(false)
  const [currentMargin, setCurrentMargin] = useState(0)
  const [paddingRight, setPaddingRight] = useState(0)
  const [clickable, setClickable] = useState(true)
  const argumentContainer = useRef(null)
  const rightContainer = useRef(null)
  const itemsRef = useRef([])
  const width = useWindowWidth()
  const isMobile = width <= parseInt(variables.mobile)

  const calcMargin = (direction, style) => {
    const offset =
      itemsRef.current && itemsRef.current.length
        ? itemsRef.current[0].offsetWidth
        : 0
    const tmp = parseInt(style.marginLeft) + direction * offset
    if (-1 * offset * (section.blocks.length - 1) <= tmp && tmp <= 0) {
      return tmp
    }
  }
  const scrollToNext = direction => {
    if (argumentContainer.current !== null && clickable) {
      const style = window.getComputedStyle(argumentContainer.current)
      const margin = calcMargin(direction, style)
      setCurrentMargin(Math.abs(margin))
      argumentContainer.current.style.marginLeft = `${margin}px`
      setClickable(false)
      setTimeout(() => {
        setClickable(true)
      }, 300)
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => scrollToNext(-1),
    onSwipedRight: () => scrollToNext(1),
  })
  const refPassthrough = el => {
    handlers.ref(el)
    argumentContainer.current = el
  }

  useEffect(() => {
    setPaddingRight(
      rightContainer && rightContainer.current
        ? `${rightContainer.current.offsetWidth - 840}px`
        : 0
    )
  }, [width])

  return (
    <section id="whyus">
      <div>
        <div className="left-container">
          <h3>{section.title}</h3>
          <div className="arrow-container">
            {!!currentMargin && (
              <Arrow
                role="button"
                className="arrow left"
                onClick={() => scrollToNext(1)}
              />
            )}
            {isMobile &&
              argumentContainer &&
              argumentContainer.current &&
              parseInt(argumentContainer.current.offsetWidth) - 350 >
                currentMargin && (
                <Arrow
                  role="button"
                  className="arrow right"
                  onClick={() => scrollToNext(-1)}
                />
              )}
          </div>
        </div>
        <div className="right-container" ref={rightContainer}>
          <div
            className="argument-container"
            {...handlers}
            ref={refPassthrough}
          >
            {section.blocks
              .sort((a, b) => a.order - b.order)
              .map((b, i) => (
                <div
                  key={i}
                  onClick={() => setOpen(open => !open)}
                  ref={el => (itemsRef.current[i] = el)}
                >
                  <div className="title-container">
                    <h4>{b.title}</h4>
                  </div>
                  <div className={`text-container${open ? " open" : ""}`}>
                    <ReactMarkdown children={b.content} remarkPlugins={[remarkGfm]} />
                  </div>
                </div>
              ))}
          </div>
          {!isMobile && (
            <div
              className="arrow-container"
              style={{
                paddingRight,
              }}
            >
              {argumentContainer &&
                argumentContainer.current &&
                parseInt(argumentContainer.current.offsetWidth) - 350 >
                  currentMargin && (
                  <Arrow
                    role="button"
                    className="arrow right"
                    onClick={() => scrollToNext(-1)}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default WhyUs
